import { render, staticRenderFns } from "./storyPart.vue?vue&type=template&id=2eb4a2bf&scoped=true&"
import script from "./storyPart.vue?vue&type=script&lang=js&"
export * from "./storyPart.vue?vue&type=script&lang=js&"
import style0 from "./storyPart.vue?vue&type=style&index=0&id=2eb4a2bf&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2eb4a2bf",
  null
  
)

export default component.exports