<template>

    <span class="part">
        <span class="part-title">{{ content.title }}</span>
        <div class="part-text">
            <div class="text" v-html="getContent()"></div>

            <a class="btn btn-link btn-showmore" tabindex="0" @keyup.space="showMore = !showMore" @keyup.enter="showMore = !showMore" @click="showMore = !showMore" v-if="btnShowMore" style="text-decoration: none;">
                <p>{{ showMore ? 'Weniger anzeigen' : 'Mehr anzeigen'}}</p>
                <i class="material-icons" aria-hidden="true">{{ showMore ? 'keyboard_arrow_up' : 'keyboard_arrow_down' }}</i>
            </a>

        </div>

        <div class="tonspur" v-if="audioWalkUrl!==null">
            <i class="material-icons" aria-hidden="true">headphones</i>
            <div class="text">
                <p class="header">{{ audioTitle }}</p>
            </div>
            <audio-player :file="audioWalkUrl"></audio-player>
        </div>

        <router-link v-if="false" class="link" title="Link zur KulturSpur Düsternbrook" :to="{ name: 'Kulturspur', params: {id: 2 } }">
            <i class="link-icon material-icons" aria-hidden="true">keyboard_arrow_right</i>
            <span>KulturSpur ansehen</span>
        </router-link>

    </span>

</template>
<script>
 import { getFieldValues, json_decode, isset, smartSubStr } from '@/utils/helpers';

 export default {
    name: "storyPart",
    components: {
        AudioPlayer: () =>import('@/components/controls/AudioPlayer.vue'),
    },
    props: {
        content: {
            type: Object,
        }
    },
    data(){
        return{
            audioTitle: 'AudioWalk',
            maxLength: 150,
            showMore: false,
        }
    },
    computed: {
        btnShowMore() {
            if (this.description.length > this.maxLength) {
                return true;
            }
            return false;
        },
        description(){
            if(this.content !== null){
                return getFieldValues(this.content,'body');
            }
            return null;
        },
        audioWalk(){
            if(this.content !== null){
                return getFieldValues(this.content, 'audiowalk');
            }
            return null;
        },  
        audioWalkTitle(){
            if(this.audioWalk !== null){
                var title = getFieldValues(this.audioWalk, 'title');
                if(title!==null){
                    return this.audioTitle = title;
                }
                return null;
            }
        },
        audioWalkDescription(){
            if(this.audiowWalk !== null){
                return getFieldValues(this.audioWalk, 'body');
            }
            return null;
        },
        audioWalkUrl(){
            if(this.audioWalk!==null){
                var audiofile = getFieldValues(this.audioWalk,'audio-datei');
                if(isset(audiofile)){
                    audiofile = json_decode(audiofile);
                    return this.$backendUrl + audiofile.path;
                }
            }
            return null
        }, 
    },
    methods: {
        json_decode,
        getFieldValues,
        smartSubStr,
        getContent(){
            return this.showMore?this.description:this.limitedString();
        },
        limitedString() {
            if (this.description.length > this.maxLength) {
                return smartSubStr(this.description, this.maxLength) + ' ...';
            }

            return this.description;
        },
    },
}
</script>

<style lang="scss" scoped>

    .part{
        padding: 10px 0px;

        &-title{
            color: #000;
            font-size: 14px;
            font-weight: 700;
            font-family: 'elliot-bold', sans-serif;
        }

        &-text{
            margin-top: 5px;

            .btn-link{
                margin-top: 10px;
                margin-bottom: 20px;
            }
        }

        .tonspur{
            margin-top: 20px;

            .text{
                margin-left: 10px;
                display: inline-block;
                vertical-align: middle;
            }

            .header{
                font-size: 14px;
                font-family: 'elliot-bold';
            }

            i.material-icons{
                font-size: 25px;
                vertical-align: middle;
            }
        }

        .btn-showmore{
            display: flex;
            justify-content: center;
            vertical-align: middle;
            align-items: center;
            text-transform: capitalize;
            color: #79798D;
            margin: 15px 0 0 0;

            i.material-icons{
              position: relative;
          }
      }

      .link{
        margin-top: 10px;
        color: #E30059;
        display: flex;
        vertical-align: middle;
        align-items: center;

    }
}
</style>
